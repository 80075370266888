import clsx from 'clsx';
import Link from 'next/link';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import LoadingComponent from '@/components/ui/buttons/loading-component/LoadingComponent';

import styles from './button.module.scss';

type CommonProps = {
  icon?: JSX.Element;
  styleVariant?: 'default' | 'outlined';
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  withoutStyles?: boolean;
};

type ButtonProps = PropsWithChildren<HTMLAttributes<HTMLButtonElement>> &
  CommonProps & {
    type?: 'submit' | 'button';
    disabledStyleVariant?: 'default' | 'grayscale';
  };

type LinkButtonProps = PropsWithChildren<HTMLAttributes<HTMLAnchorElement>> &
  CommonProps & {
    href: string;
  };

export const Button: FC<ButtonProps> = ({
  styleVariant = 'default',
  disabledStyleVariant = 'default',
  children,
  icon,
  className,
  isLoading = false,
  disabled,
  fullWidth = false,
  withoutStyles,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        className,
        !withoutStyles && styles.btn,
        styleVariant && styles[styleVariant],
        fullWidth && styles.full,
        isLoading && styles.loading,
        disabledStyleVariant && styles[`disabled-${disabledStyleVariant}`]
      )}
      disabled={disabled}
      {...rest}
    >
      {!isLoading ? (
        <>
          <span className={styles.text}>{children}</span>
          {icon && <div className={styles.icon}>{icon}</div>}
        </>
      ) : (
        <LoadingComponent />
      )}
    </button>
  );
};

export const LinkButton: FC<LinkButtonProps> = ({
  styleVariant = 'default',
  href,
  children,
  icon,
  className,
  isLoading = false,
  ...rest
}) => {
  return (
    <Link
      href={href}
      className={clsx(className, styles.btn, styleVariant && styles[styleVariant])}
      {...rest}
    >
      {!isLoading ? (
        <>
          <span className={styles.text}>{children}</span>
          {icon && <div className={styles.icon}>{icon}</div>}
        </>
      ) : (
        <LoadingComponent />
      )}
    </Link>
  );
};

export const ExternalLinkButton: FC<LinkButtonProps> = ({
  styleVariant = 'default',
  href,
  children,
  icon,
  className,
  isLoading = false,
  withoutStyles,
  ...rest
}) => {
  return (
    <a
      className={clsx(
        className,
        !withoutStyles && styles.btn,
        styleVariant && styles[styleVariant]
      )}
      target="_blank"
      rel="noreferrer"
      href={href}
      {...rest}
    >
      {!isLoading ? (
        <>
          <span className={styles.text}>{children}</span>
          {icon && <div className={styles.icon}>{icon}</div>}
        </>
      ) : (
        <LoadingComponent />
      )}
    </a>
  );
};

export default Button;
