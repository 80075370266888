import useOutsideClick from '@rooks/use-outside-click';
import clsx from 'clsx';
import React, { HTMLAttributes, useRef, useState } from 'react';

import { OptionType } from '@/store/slices/sorting-products/sorting-products.slice';

import styles from './SortingSelect.module.scss';

const DropDown = ({
  options,
  selectedOption,
  onItemClick,
}: {
  options: OptionType[];
  selectedOption: OptionType;
  onItemClick: (item: OptionType) => void;
}) => {
  return (
    <ul className={styles.dropdown}>
      {options.map((sort) => {
        const isSelected = selectedOption.name === sort.name;

        return (
          <li
            key={sort.name}
            className={clsx(styles.option, isSelected && styles.active)}
            onClick={() => onItemClick(sort)}
          >
            <span>{sort.name}</span>
            {isSelected && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
              </svg>
            )}
          </li>
        );
      })}
    </ul>
  );
};

type SortingSelectProps = HTMLAttributes<HTMLButtonElement> & {
  value: OptionType;
  options: OptionType[];
  onOptionSelect: (item: OptionType) => void;
};

const SortingSelect = ({ value, className, options, onOptionSelect }: SortingSelectProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const onItemClick = (item: OptionType) => {
    setIsDropdownOpen(false);
    onOptionSelect(item);
  };

  const selectRef = useRef<any>(null);
  useOutsideClick(selectRef, () => setIsDropdownOpen(false));

  return (
    <button
      className={clsx(className, styles.button)}
      ref={selectRef}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <div className={styles.title}>{value.name}</div>
      <div className={clsx(styles.icon, isDropdownOpen && styles.active)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
        </svg>
      </div>
      {isDropdownOpen && (
        <DropDown options={options} selectedOption={value} onItemClick={onItemClick} />
      )}
    </button>
  );
};

export default SortingSelect;
