import clsx from 'clsx';
import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { DeepMap, FieldError, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import ErrorMessage from '@/components/ui/form-elements/error-message/ErrorMessage';

import styles from './Input.module.scss';

export type InputType = 'text' | 'email';

export type InputProps = {
  id?: string;
  name?: string;
  label: string;
  type?: InputType;
  className?: string;
  isPhone?: boolean;
  isBirthday?: boolean;
  isValid?: boolean;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, ''>;

export type FormInputProps<TFormValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<InputProps, 'name'>;

export const Input: FC<InputProps> = ({
  label,
  isValid,
  name,
  className,

  ...props
}) => {
  return (
    <div
      className={clsx(className, styles.wrapper, isValid && styles.validBorder)}
      aria-live="polite"
    >
      <input className={styles.floatingInput} {...props} placeholder=" " />{' '}
      <label className={styles.label}>{label}</label>
      {isValid && (
        <div className={styles.valid}>
          <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
              <path
                d="M15 4.5L6.75 12.75L3 9"
                stroke="#ef0073"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export const FormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  label,
  className,
  isPhone = false,
  isBirthday = false,
  isValid = false,
  disabled,
  ...props
}: FormInputProps<TFormValues>) => {
  return (
    <div className={clsx(styles[`block-wrapper`], className)}>
      {register && (
        <div
          className={clsx(
            styles.wrapper,
            errors && errors[name] && styles.error,
            isValid && styles.validBorder
          )}
        >
          {renderInputs({
            name,
            register,
            rules,
            errors,
            label,
            className,
            isPhone,
            isBirthday,
            isValid,
            disabled,
            ...props,
          })}
          <label className={styles.label}>{label}</label>
          {isValid && (
            <div className={styles.valid}>
              <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                  <path
                    d="M15 4.5L6.75 12.75L3 9"
                    stroke="#1DB175"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
          )}
        </div>
      )}
      {errors && errors[name] && <ErrorMessage error={errors[name]?.message} />}
    </div>
  );
};

export const renderInputs = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  label,
  className,
  isPhone = false,
  isBirthday = false,
  isValid = false,
  disabled,
  ...props
}: FormInputProps<TFormValues>) => {
  const inputClassName = clsx(styles.floatingInput, disabled && styles.disabled);
  if (register) {
    const { ref: registerRef, ...registerProps } = register(name, rules);
    return (
      <>
        {isPhone ? (
          <PatternFormat
            mask="_"
            format={'+7 (###) ### ## ##'}
            placeholder=" "
            className={inputClassName}
            type="tel"
            inputMode="numeric"
            // @ts-ignore-next-line
            data-testid={props['data-testid']}
            getInputRef={registerRef}
            {...registerProps}
          />
        ) : isBirthday ? (
          <PatternFormat
            mask="_"
            format={'##.##.####'}
            className={inputClassName}
            inputMode="numeric"
            // @ts-ignore-next-line
            data-testid={props['data-testid']}
            getInputRef={registerRef}
            {...registerProps}
          />
        ) : (
          <input
            className={inputClassName}
            placeholder=" "
            {...props}
            {...(register && register(name, rules))}
          />
        )}
      </>
    );
  }
};
