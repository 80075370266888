import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import { PatternFormat } from 'react-number-format';

// import styles from '../form-elements.module.scss';
import styles from './Field.module.scss';

interface FieldProps {
  className?: string;
  labelText: string;
  variant?: 'default' | 'white';
  error?: string;
  type?: 'phone' | 'birthday';
  mask?: string;
  format?: string;
  isValidIconBooleanQuery?: boolean;
  isLoading?: boolean;
  value: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
}

export const Field = ({
  name,
  labelText,
  value,
  onChange,
  type = 'phone',
  error,
  disabled = false,
  variant = 'default',
  mask,
  format,
  className,
  isValidIconBooleanQuery = false,
  isLoading,
}: FieldProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  const returnCurrentInput = () => {
    const options = {
      value: value,
      onChange: onChange,
      name: name,
      id: name,
      disabled: disabled || isLoading,
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
    };

    switch (type) {
      case 'phone':
        return (
          <PatternFormat
            mask={mask ? mask : '_'}
            type="tel"
            allowEmptyFormatting
            format={focus || value ? (format ? format : '+7 (###) ### ## ##') : ''}
            {...options}
          />
        );
      case 'birthday':
        return (
          <PatternFormat
            mask={mask ? mask : '_'}
            allowEmptyFormatting
            format={focus || value ? (format ? format : '##.##.####') : ''}
            {...options}
          />
        );
    }
  };

  return (
    <div
      className={clsx(
        className,
        styles.inputContainer,
        value && styles.filled,
        error && styles.error,
        variant === 'white' && styles.white
      )}
    >
      <label htmlFor={name}>{labelText}</label>
      {returnCurrentInput()}
      {isLoading && <span className={styles.loader} />}
      {isValidIconBooleanQuery && (
        <div className={styles.icon}>
          <svg
            width={24}
            height={24}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.8">
              <path
                d="M15 4.5L6.75 12.75L3 9"
                stroke="#1DB175"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

Field.displayName = 'Field';
