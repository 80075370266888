import clsx from 'clsx';
import { motion } from 'framer-motion';
import Router from 'next/router';
import { FC } from 'react';

import { Container } from '@/components/container';
import { Image } from '@/components/image';

import styles from './PageReturnBackBlock.module.scss';

const ReturnBackBlock: FC<{ toCategory?: boolean; search?: boolean }> = ({
  toCategory,
  search,
}) => {
  const handleBack = () => Router.back();
  const handleToIndex = () => Router.push('/');
  return (
    <Container variant="xl" className={styles.relative_container}>
      <motion.div
        className={search ? clsx(styles.back_btn, styles.back_btn__noNone) : styles.back_btn}
        whileTap={{ scale: 0.95 }}
        onClick={toCategory ? handleToIndex : handleBack}
      >
        <Image src={'/static/images/arrow-left.svg'} width={35} height={35} alt="Вернуться назад" />
      </motion.div>
    </Container>
  );
};
export default ReturnBackBlock;
