import clsx from 'clsx';
import { FC } from 'react';
import Switch from 'react-switch';

import styles from './Toggler.module.scss';

interface TogglerProps {
  label?: string;
  checked: boolean;
  onChange: any;
  className?: string;
  labelPosition?: 'right' | 'left';
  switchClassname?: string;
}

const Toggler: FC<TogglerProps> = ({
  label,
  labelPosition = 'left',
  checked,
  onChange,
  className,
  switchClassname,
  ...rest
}) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {label && labelPosition === 'left' && <span onClick={onChange}>{label}</span>}
      <Switch
        checked={checked}
        offHandleColor="#d5d6db"
        height={32}
        offColor="#f7f7f7"
        uncheckedIcon={false}
        checkedIcon={false}
        borderRadius={16}
        handleDiameter={22}
        className={clsx(switchClassname, styles.switch)}
        onChange={onChange}
        onHandleColor="#1DB175"
        onColor="#E0FFF2"
        {...rest}
      />
      {label && labelPosition === 'right' && <span onClick={onChange}>{label}</span>}
    </div>
  );
};

export default Toggler;
