import { useRouter } from 'next/router';
import { any } from 'prop-types';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useMemo } from 'react';

import { parseUrlToFilters } from '@/helpers/parseUrlToFilters';
import { getCategoriesIdsByUrl } from '@/helpers/url';

import { useGetAvailableAttributesQuery } from '@/store/api/availableAttributes.api';
import { useGetCategoriesQuery } from '@/store/api/categories.api';

interface UseRouterParamsOptions {
  method?: 'push' | 'replace';
  shallow?: boolean;
}

const BUKETY_CATEGORY_ID = 70;

export const useRouterParams = (options?: UseRouterParamsOptions) => {
  const router = useRouter();
  const { query, pathname, push, replace, asPath } = router;
  // TODO: determine from router
  const dynamicRouteName = 'catalogFilters';

  const { data: categories } = useGetCategoriesQuery();
  const category = useMemo(() => {
    if (categories) {
      return getCategoriesIdsByUrl(router.asPath ?? '', categories).join(',');
    }

    return '';
  }, [categories, router.asPath]);
  const { data: availableAttributes } = useGetAvailableAttributesQuery({ categories: category });

  const omitKeys = <T extends Record<string, any>>(obj: T, keys: string[]) =>
    Object.keys(obj).reduce((acc, key) => {
      if (!keys.includes(key)) {
        (acc as Record<string, any>)[key] = obj[key];
      }
      return acc;
    }, {} as T);

  const paramsFromClient = useMemo(
    () =>
      categories && availableAttributes
        ? parseUrlToFilters(asPath, categories, availableAttributes)
        : {},
    [categories, availableAttributes, asPath]
  );

  const attrValues = useMemo(() => {
    return availableAttributes?.map((attr) => attr.attr_values).flat();
  }, [availableAttributes]);
  const attrLabelsSlugs = useMemo(() => {
    return availableAttributes?.map((attr) => attr.slug);
  }, [availableAttributes]);
  const reload = options?.method === 'replace' ? replace : push;
  const shallow = options?.shallow ?? true;

  /**
   * Checks whether a param is exposed in the URL string or not.
   * @param name The name of the param.
   * @param value Optional, the param must have the specified value.
   * @returns true/false depending on the presence of the param.
   */
  const hasParam = useCallback(
    (name: string, value?: string | number | boolean) => {
      // @ts-ignore
      return paramsFromClient[name] && paramsFromClient[name].includes(value);
    },
    [paramsFromClient]
  );
  /**
   *  Retrieves from the URL the value of the provided param.
   * @param name The name of the param.
   * @returns The value of the param.
   */
  const getParamValue = useCallback(
    (name: string) => {
      // @ts-ignore
      return paramsFromClient[name] ?? [];
    },
    [paramsFromClient]
  );

  /**
   * If no argument is passed, it clears all the query params from the URL.
   * If one or more params are passed as arguments, only those will be cleared
   * from the URL.
   * @param params one or more params to remove.
   */
  const clearParams = useCallback(
    (...params: string[]) => {
      // Clear all params
      if (!params.length) {
        reload(
          {
            pathname,
          },
          undefined,
          { shallow }
        );
        return;
      }
      // Clear the given params
      const newQuery = Object.keys(query).reduce((acc, curr) => {
        if (!params.includes(curr)) {
          acc[curr] = query[curr];
        }
        return acc;
      }, {} as ParsedUrlQuery);

      if (params.some((param) => ['color', 'flower'].includes(param))) {
        params.forEach((param) => {
          availableAttributes
            ?.find((a) => a.slug === param)
            ?.attr_values.forEach((attrValue) => {
              newQuery[dynamicRouteName] = (
                Array.isArray(newQuery[dynamicRouteName])
                  ? newQuery[dynamicRouteName]
                  : [newQuery[dynamicRouteName]]
              )
                .filter((dynamicRoute): dynamicRoute is string => typeof dynamicRoute === 'string')
                .filter((dynamicRoute) => attrValue.slug !== dynamicRoute);
            });
        });
      }

      if (
        newQuery[dynamicRouteName]?.length === 1 &&
        categories?.find((c) => c.slug === newQuery[dynamicRouteName]?.[0]) &&
        !Object.keys(newQuery).filter((key) => key !== dynamicRouteName).length
      ) {
        newQuery.sort = ['rank'];
      }

      reload(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { shallow }
      );
    },
    [availableAttributes, categories, pathname, query, reload, shallow]
  );

  /**
   * Removes the provided params with a specific value from the URL.
   * @param name The name of the param.
   * @param value The value of the param.
   */
  const removeParam = useCallback(
    (name: string, value?: string | number | boolean | string[] | number[] | boolean[]) => {
      const { [name]: param, ...rest } = query;

      if (!param) {
        return;
      }

      let newQuery;
      if (value && Array.isArray(param) && !Array.isArray(value)) {
        newQuery = {
          ...rest,
          [name]: param.filter((element) => element !== encodeURIComponent(value)),
        };
      } else {
        newQuery = { ...rest };
      }

      reload(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { shallow }
      );
    },
    [pathname, query, reload, shallow]
  );

  /**
   * It sets a query param in the URL to a given value. If it already exists, it
   * will be overriden.
   * @param name The name of the param.
   * @param value The value of the param, it can be single or multiple values.
   */
  const setParam = useCallback(
    (name: string, value?: string | boolean | number | string[] | boolean[] | number[]) => {
      if (!value) {
        removeParam(name);
        return;
      }
      const rest = omitKeys(query, [name, 'page']);
      const page =
        name === 'page' ? { page: value || 0 } : query.page ? { page: query.page[0] } : {};

      reload(
        {
          pathname,
          query: {
            ...rest,
            [name]: Array.isArray(value)
              ? value.map((el) => encodeURIComponent(el))
              : encodeURIComponent(value),
            ...page,
          },
        },
        undefined,
        { shallow }
      );
    },
    [pathname, query, reload, removeParam, shallow]
  );

  const getSlugsByIds = useCallback(
    (ids: (string | number)[]) =>
      ids.map((id) => attrValues?.find((attrValue) => attrValue.id === id)?.slug ?? ''),
    [attrValues]
  );

  /**
   * Adds the query param to the URL if it's not already there or removes it
   * otherwise.
   * @param name The name of the param.
   * @param value The value of the param.
   */
  const toggleParam = useCallback(
    (name: string, value: string | boolean | number) => {
      const newParams = { ...paramsFromClient };

      if (name === 'category') {
        newParams.category = [value];
        attrLabelsSlugs?.forEach((slug) => {
          delete newParams[slug];
        });
      } else if (
        paramsFromClient.category?.[0] &&
        paramsFromClient.category[0] !== BUKETY_CATEGORY_ID
      ) {
        newParams[name] = newParams[name]?.[0] === value ? [] : [value];
      } else if (newParams[name] && newParams[name]?.includes(value)) {
        newParams[name].splice(
          newParams[name].findIndex((id: number | string) => id == value),
          1
        );
      } else {
        newParams[name] = (newParams[name] || []).concat(value);
      }

      if (!newParams[name].length) {
        delete newParams[name];
      }

      const paramsKeys = Object.keys(newParams);
      const categoryOnlySelected =
        !paramsKeys.some((key) => attrLabelsSlugs?.includes(key)) &&
        paramsKeys.includes('category');
      if (categoryOnlySelected) {
        newParams.sort = newParams.sort ?? ['rank'];
      } else if (newParams.sort?.[0] === 'rank') {
        delete newParams.sort;
      }

      const categorySlugs = newParams['category']
        ? categories
            ?.filter((cat) =>
              (newParams['category'] || []).some((id: string) => parseInt(id) === cat.id)
            )
            .map((cat) => cat.slug) ?? []
        : [];

      const slugs: string[] = categorySlugs
        .concat(getSlugsByIds(newParams['flower'] || []))
        .concat(getSlugsByIds(newParams['color'] || []));

      const restFilter = omitKeys(newParams, ['category', 'flower', 'color', 'page']);
      const page = newParams.page ? { page: newParams.page[0] } : {};

      reload(
        {
          pathname,
          query: { ...restFilter, [dynamicRouteName]: slugs, ...page },
        },
        undefined,
        { shallow }
      );
    },
    [attrLabelsSlugs, categories, getSlugsByIds, paramsFromClient, pathname, reload, shallow]
  );

  return {
    hasParam,
    getParamValue,
    setParam,
    clearParams,
    removeParam,
    toggleParam,
    filters: paramsFromClient,
  };
};
