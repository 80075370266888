import clsx from 'clsx';
import React, { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';
import { DeepMap, FieldError, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import ErrorMessage from '@/components/ui/form-elements/error-message/ErrorMessage';

import styles from './Textarea.module.scss';

export type TextareaProps = {
  id?: string;
  name?: string;
  label: string;
  className?: string;
} & Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, ''>;

export type FormTextareaProps<TFormValues> = {
  //@ts-ignore
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<TextareaProps, 'name'>;

export const Textarea: FC<TextareaProps> = ({ label, name, className, ...props }): JSX.Element => {
  return (
    <div className={clsx(className, styles.wrapper)} aria-live="polite">
      <textarea className={styles.floatingTextarea} {...props} placeholder=" " />{' '}
      <label className={styles.label}>{label}</label>
    </div>
  );
};

export const FormTextarea = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  label,
  className,
  ...props
}: FormTextareaProps<TFormValues>): JSX.Element => {
  return (
    <div className={clsx(styles[`block-wrapper`], className)}>
      <div className={clsx(styles.wrapper, errors && errors[name] && styles.error)}>
        <textarea
          className={styles.floatingTextarea}
          placeholder=" "
          rows={6}
          {...props}
          {...(register && register(name, rules))}
        />

        <label className={styles.label}>{label}</label>
      </div>
      {errors && errors[name] && <ErrorMessage error={errors[name]?.message} />}
    </div>
  );
};
