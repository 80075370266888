import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

import styles from './styles.module.scss';

type ButtonType = {
  onClick: () => void;
};

const arrowLeft = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6L9 12L15 18"
      stroke="#1D1D1D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SliderButtonLeft: FC<HTMLAttributes<HTMLDivElement> & ButtonType> = ({
  className,
  onClick,
}) => {
  return (
    <div className={clsx(className, styles.navigation_btn)} onClick={onClick}>
      {arrowLeft}
    </div>
  );
};
