import { FC } from 'react';

import { Image } from '@/components/image';

import styles from './LoadingComponent.module.scss';
import HalilusyaIcon from './halilusya.png';

const LoadingComponent: FC = () => {
  return (
    <div className={styles.loadingComponent}>
      <span>Секундочку</span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.spinner}
      >
        <circle cx="12" cy="12" r="11" stroke="white" strokeWidth="2" />
        <path
          d="M10.4615 22.8919C8.42826 22.6047 6.51605 21.7541 4.94121 20.4364C3.36638 19.1188 2.1918 17.3866 1.5504 15.436C0.909007 13.4853 0.826403 11.3941 1.31194 9.39897C1.79747 7.40383 2.83176 5.58441 4.29775 4.14663C5.76375 2.70885 7.60291 1.7101 9.60712 1.26342C11.6113 0.816741 13.7005 0.939959 15.6383 1.61913C17.5761 2.2983 19.2852 3.50631 20.572 5.10645C21.8588 6.70659 22.6721 8.63497 22.9197 10.6734"
          stroke="#fc492d"
          strokeWidth="2"
        />
      </svg>

      <div className={styles.halilusya}>
        <Image src={HalilusyaIcon} fill priority alt="" />
      </div>
    </div>
  );
};

export default LoadingComponent;
